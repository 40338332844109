<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus" />
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'SignInfoIndex',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        {
          "icon": "doc-success",
          display_name: '签约信息',
          url: '/sign/info',
          permission_name: 'sign_info_page',
        },
        {
          "icon": "file-conversion-one",
          display_name: '签约录入',
          url: '/sign/create',
          permission_name: 'sign_enter_page',
        },
        {
          "icon": "zhuanzu",
          display_name: '红人转组',
          url: '/sign/transfer_group',
          permission_name: 'sign_transfer_group',
        },
        {
          "icon": "jieyue",
          display_name: '红人解约',
          url: '/sign/sign_fired',
          permission_name: 'sign_fired_page',
        }
        ,
        {
          "icon": "qianyue",
          "display_name": "签约情况一览",
          "url": "/sign/sign_overview",
          "permission_name": "sign_summary_page"
        }
      ],
    }
  },
}
</script>

<style scoped></style>
